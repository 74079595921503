exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-components-about-celebration-tsx": () => import("./../../../src/pages/about-components/about-celebration.tsx" /* webpackChunkName: "component---src-pages-about-components-about-celebration-tsx" */),
  "component---src-pages-about-components-about-description-tsx": () => import("./../../../src/pages/about-components/about-description.tsx" /* webpackChunkName: "component---src-pages-about-components-about-description-tsx" */),
  "component---src-pages-about-components-about-images-tsx": () => import("./../../../src/pages/about-components/about-images.tsx" /* webpackChunkName: "component---src-pages-about-components-about-images-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-animated-logo-btn-animated-logo-btn-tsx": () => import("./../../../src/pages/animated-logo-btn/animated-logo-btn.tsx" /* webpackChunkName: "component---src-pages-animated-logo-btn-animated-logo-btn-tsx" */),
  "component---src-pages-animated-logo-btn-animated-logo-svg-tsx": () => import("./../../../src/pages/animated-logo-btn/animated-logo-svg.tsx" /* webpackChunkName: "component---src-pages-animated-logo-btn-animated-logo-svg-tsx" */),
  "component---src-pages-career-detail-strapi-career-application-url-id-tsx": () => import("./../../../src/pages/career-detail/{strapiCareerApplication.urlId}.tsx" /* webpackChunkName: "component---src-pages-career-detail-strapi-career-application-url-id-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-home-components-home-our-services-tsx": () => import("./../../../src/pages/home-components/home-our-services.tsx" /* webpackChunkName: "component---src-pages-home-components-home-our-services-tsx" */),
  "component---src-pages-home-components-home-recent-works-tsx": () => import("./../../../src/pages/home-components/home-recent-works.tsx" /* webpackChunkName: "component---src-pages-home-components-home-recent-works-tsx" */),
  "component---src-pages-home-components-home-secondary-title-tsx": () => import("./../../../src/pages/home-components/home-secondary-title.tsx" /* webpackChunkName: "component---src-pages-home-components-home-secondary-title-tsx" */),
  "component---src-pages-home-components-home-subtitle-tsx": () => import("./../../../src/pages/home-components/home-subtitle.tsx" /* webpackChunkName: "component---src-pages-home-components-home-subtitle-tsx" */),
  "component---src-pages-home-components-home-title-tsx": () => import("./../../../src/pages/home-components/home-title.tsx" /* webpackChunkName: "component---src-pages-home-components-home-title-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-project-detail-project-detail-components-project-detail-change-tsx": () => import("./../../../src/pages/project-detail/project-detail-components/project-detail-change.tsx" /* webpackChunkName: "component---src-pages-project-detail-project-detail-components-project-detail-change-tsx" */),
  "component---src-pages-project-detail-project-detail-components-project-detail-intro-tsx": () => import("./../../../src/pages/project-detail/project-detail-components/project-detail-intro.tsx" /* webpackChunkName: "component---src-pages-project-detail-project-detail-components-project-detail-intro-tsx" */),
  "component---src-pages-project-detail-project-detail-components-project-detail-logos-tsx": () => import("./../../../src/pages/project-detail/project-detail-components/project-detail-logos.tsx" /* webpackChunkName: "component---src-pages-project-detail-project-detail-components-project-detail-logos-tsx" */),
  "component---src-pages-project-detail-strapi-project-title-tsx": () => import("./../../../src/pages/project-detail/{strapiProject.title}.tsx" /* webpackChunkName: "component---src-pages-project-detail-strapi-project-title-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

